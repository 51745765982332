import { render, staticRenderFns } from "./arrow-right-icon.vue?vue&type=template&id=6fea7d66&scoped=true&"
import script from "./arrow-right-icon.ts?vue&type=script&lang=ts&"
export * from "./arrow-right-icon.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fea7d66",
  null
  
)

export default component.exports