<template>
  <div id="wrapper">
    <NavBar />
    <SideBar
      :is-condensed="isMenuCondensed"
      v-on:toggle-menu="toggleMenu"
    />

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
    </div>

    <MobileNavBar />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SideBar from '@/components/features/side-bar.vue';
import NavBar from '@/components/features/nav-bar/nav-bar.vue';
import MobileNavBar from '@/components/features/mobile-nav-bar/mobile-nav-bar.vue';

@Component({
  components: {
    SideBar,
    NavBar,
    MobileNavBar,
  }
})
export default class Main extends Vue {
  isMenuCondensed = false;

  @Getter('getCurrentUser', { namespace: 'auth' })
  currentUser;

  created() {
    if (document.documentElement.clientWidth < 768) {
      document.body.classList.add('sidebar-enable');
      document.body.classList.add('enlarged');
    } else {
      document.body.classList.remove('authentication-bg');
      document.body.classList.remove('authentication-bg-pattern');
    }
  }

  toggleMenu() {
    this.isMenuCondensed = !this.isMenuCondensed;
    document.body.classList.toggle('sidebar-enable');

    // if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
    document.body.classList.toggle('enlarged');
    // }
  }
}
</script>
