<template>
  <!-- ========== Left Sidebar Start ========== -->
  <SideNav
      :is-condensed="isCondensed"
      v-on:toggle-menu="onToggleMenu"
      v-on:toggle-right-sidebar="toggleRightSidebar"
      class="left-side-menu"
  />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import SideNav from './side-nav/side-nav.vue';

@Component({
  components: { SideNav },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
  }
})
export default class SideBar extends Vue {
  onToggleMenu() {
    this.$emit('toggle-menu');
  }

  toggleRightSidebar() {
    this.onToggleMenu()
  }
}
</script>

<style lang="scss">
.slimscroll-menu {
  height: 100%;
}

.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}

.left-side-menu {
  transition: transform .2s ease-out;

  .nprogress-busy &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 1023.98px) {
  .left-side-menu {
    transform: translateX(-100%);
    box-shadow: none;
  }
}
</style>
