<template>
  <div class="entrance-wrapper">
    <slot />
    <footer class="footer footer-alt" />
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import LangWidget from '@/components/ui/lang-widget/langWidget.vue';

  @Component({
    components: { LangWidget }
  })
  export default class Auth extends Vue {}
</script>